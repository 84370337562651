/**
 * @generated SignedSource<<253078632357433f58b61683db73a3c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeaderContentFragment$data = {
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  };
  readonly customAppTitle: string | null;
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly product: {
    readonly " $fragmentSpreads": FragmentRefs<"LearnModeSwitchFragment">;
  } | null;
  readonly " $fragmentType": "HeaderContentFragment";
};
export type HeaderContentFragment$key = {
  readonly " $data"?: HeaderContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LearnModeSwitchFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "3dbf2ae8aa6f2147ae24e2442cebe83c";

export default node;
